.navbar {
  height: 44px;
  width: 100%;
  background: rgb(24, 24, 24);
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 99999;
}

.navbar-header-content {
  margin-left: 10px;
}

.navbar-logo {
  align-self: center;
  height: 26px;
  margin: 5px;
}

.navbar-header {
  margin-left: 10px;
  align-self: center;
}

.navbar-icon {
  align-self: center;
  margin: 10px;
}

.navbar-icon-text {
  margin-right: 20px;
}

.button-icon:hover {
  cursor: pointer;
  transition: all 330ms;
}

.button-icon:hover .navbar-icon-text {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #b68b2f 30%, #b99541 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #927531 62.5%, #8a7033 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Abel;
  margin-left: 0.5em;
}

.button-icon:hover .navbar-icon {
  fill: url(#gold-gradient) goldenrod;
}
