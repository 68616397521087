.login-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.login-form-width {
    display: flex;
    width: 35%;
    max-width: 700px;
    min-width: 550px;
}

.form-login {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 3rem;
    padding-left: 3rem;
    border: 1px solid grey;
    border-radius: 5;
    box-shadow: 0 0 5px 2px #797d79;
    background-color:white;
}

.text-center {
    text-align: center;
    vertical-align: middle;
    font-size: 0.9vw;
}

.logo-login-main{
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logo-container-login {
    width: 46%;
    position: relative;
    margin: 2%;
}

.text-signin {
    text-align: center;
    vertical-align: middle;
    font-size: 1.4vw;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-family: abel;
}

.pt{
    padding-top: 1.5rem;
}

.label-form-login {
    font-size: 0.9rem;
    margin-bottom: 3px;
    margin-top: 10px;
    font-family: abel;
}

.logo-login {
    width: 100%;
    height: 38px;
}

.text-login {
    width: 100%;
    text-align: center;
}

.user-login{
    width:93.8%;
}

.videotem{
    width: 60%;   
}

.pass-wrapper {
    position: relative;
    display: flex;
    width: 100%;
}
  
.icon-login {
    position: absolute;
    top: 20%;
    right: 5%;
}

.icon-login:hover {
    cursor: pointer;
    fill: url(#gold-gradient) goldenrod;
}

.button {
    display: inline-block;
    border-radius: 4px;
    background-color: black;
    background-image: linear-gradient(to right, black, darkgoldenrod);
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 1rem;
    width: 100%;
    transition: all 0.5s;
    cursor: pointer;
    font-size: 1.4em;
    font-family: Abel;
}
  
.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.7s;
}
  
.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -1rem;
    transition: 0.7s;
}
  
.button:hover span {
    padding-right: 2rem;
}
  
.button:hover span:after {
    opacity: 1;
    right: 0;
}








