.table-container{
    position: relative;
    margin: 10px 5px;
    width: 100%;
    height: 100%;
    font-size:0.7em;
}
.fixed-table {
    position: relative;
    width: 100%;
    height: 100%;
}
.fixed-table__scrollable-sidebar {
    position: relative;
    height: 100%;
    top: 20px;
    overflow: hidden;
}
.fixed-table__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 105px;
    height: 100%;
}
.fixed-table__sidebar .fixed-table__col {
    width: 100%;
}
.fixed-table__body {
    position: relative;
    margin-left: 115px;
    max-width: calc(100% - 50px);
    height: 100%;
}
.fixed-table__body .fixed-table__list {
    top: 20px;
}
.fixed-table__list {
    position: relative;
    height:148px;
}
.fixed-table__list--fixed {
    height: auto;
    overflow: visible;
}
.fixed-table__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 18px;
    overflow: hidden;
    font-size: 1em;
    border-bottom: 1px solid gray;
}
.fixed-table__scrollable-header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
}
.fixed-table__th {
    flex-shrink: 0;
    width: 70px;
    padding: 0 2px;
}
.fixed-table__row {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
.fixed-table__col {
    flex-shrink: 0;
    width: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 1px solid gray;
    padding: 2px;
}
