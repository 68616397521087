.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 150px;
    height: 25px;
    border-radius: 50px;
    position: relative;
    transition: background-color .2s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 75px;
    height: 16px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    text-align:center;
    padding-top:2.5px;
    padding-bottom:2.5px;
    vertical-align: middle;
    font-family:Abel;
    font-size: 0.8em;
    color:black;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 90px;
}