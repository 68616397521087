.tableFixHead {
    table-layout: fixed;
    border-collapse: collapse;
    color:white;
  }
    .tableFixHead tbody {
    display: block;
    width: 100%;
  }
  .tableFixHead thead tr {
    display: block;
    /* width:460px; */
  }
  .tableFixHead th,
  .tableFixHead  td {
    padding: 5px 10px;
    width: 130px;
  }
  .tableFixHead th {
    background: black;
  }

  .tableFixHead tbody tr:nth-child(even){background-color: #f2f2f2}
  .tableFixHead tbody tr:nth-child(odd){background-color: #dbdbdb}
  .tableFixHead tbody tr{color:black}