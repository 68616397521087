.bubble-wysiwyg {
  font-family: Abel;
  position: relative;
  font-size: 0.9em;
  border: none;
  outline: none;
}

.bubble-wysiwyg-left {
  font-family: Abel;
  position: relative;
  font-size: 0.9em;
  border: none;
  outline: none;
  background-color: #90da90;
}

.bubble-wysiwyg-editor {
  font-family: Abel;
  font-size: 0.9em;
  margin: -9px;
}