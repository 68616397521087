.marginDomainBoxPlot{
    margin-left: 1em;
    visibility: hidden;
}

.tooltip-boxplot{
    font-size:12px;
    position:absolute;
    text-align:center;
    padding:8px;
    background-color:rgba(218, 165, 32, 0.8);
    border:0px;
    border-radius: 8px;
    pointer-events:none;
    color:black;
}