#cesiumContainer {
  position: absolute;
  width: 100%;
  transition: all 500ms;
}

.target-cursor {
  cursor: crosshair;
}

.pointer-cursor {
  cursor: pointer;
}

.cesium-coordinate-text {
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
}

.cesium-scale-line {
  border-top: 3px solid white;
  border-left: 3px solid white;
  border-right: 3px solid white;
  height: 5px;
  margin-left: 20px;
}

.cesium-scale-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 18px;
  margin-right: -10px;
}

.cesium-viewer-geocoderContainer {
  margin-top: 16em !important;
}

.cesium-geocoder-input {
  border-radius: 10px;
}

.cesium-geocoder-searchButton {
  border-radius: 10px;
}