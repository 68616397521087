.sidebar {
  display: flex;
  flex-direction: column;
  background: rgb(24, 24, 24);
  height: 100vh;
  width: 50px;
  z-index: 999999;
}

.sidebar-icon {
  margin: 10px;
  cursor: pointer;
  padding: 8px;
}

.sidebar-box {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  transition: 0.5s;
  padding-left: 10px;
  margin: 0px 20px 0px 0px;
  border-left: 2px solid transparent;
  -webkit-transition:0.5s;
}

.sidebar-icon:hover {
  fill: url(#gold-gradient) goldenrod;
  border-left: 2px solid goldenrod;
}

.sidebar-icon-active {
  fill: url(#gold-gradient) goldenrod;
  border-left: 2px solid goldenrod;
}

.sidebar-icon:hover .description-button {
  position: absolute;
  display: inline-block;
}

.description-button {
  display: none;
}
