.globe-yellow-button {
  height: 10px;
  width: 10px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  transition: transform 330ms;
}

.globe-yellow-button:hover {
  transform: scale(1.3);
}

.globe-yellow-button:hover .gold-ring {
  width: 12vh;
  min-width: 90px;
  visibility: visible;
}

.globe-yellow-button:hover .silver-ring {
  visibility: hidden;
}

.globe-font-size {
  font-size: 0.9em;
}

.globe-text {
  text-align: center;
  position: absolute;
  font-family: 'Abel';
  font-weight: 700;
}

.black-globe-button {
  width: 11vh;
  min-width: 81px;
  position: fixed;
}

.gold-ring {
  -webkit-animation: spin 6s infinite;
  -moz-animation: spin 6s infinite;
  animation: spin 6s infinite;
  width: 12vh;
  min-width: 89px;
  position: fixed;
  visibility: hidden;
}

.silver-ring {
  -webkit-animation: spin 6s infinite;
  -moz-animation: spin 6s infinite;
  animation: spin 6s infinite;
  width: 12vh;
  min-width: 89px;
  position: fixed;
}

@-moz-keyframes spin {
  100% { 
    -moz-transform: rotate(360deg);
  } 
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
