.globe-flex {
  position: relative;
  display: flex;
  z-index: 3002;
  align-items: center;
  width: 32em;
  overflow: hidden;
  cursor: all-scroll;
  max-height: 100%;
  animation-duration: 1s;
}

.grey-screen-circle {
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  height: 150%;
  width: 400%;
  z-index: 1001;
  position: absolute;
  margin-left: calc(30px - (450px - 250px) / 2);
  overflow: hidden;
  right: 30%;
  opacity: 0.8;
  z-index: 998;
}

.gold-screen-circle {
  border: 5px solid rgba(255, 255, 255);
  height: 150%;
  width: 400%;
  border-radius: 50%;
  z-index: 1001;
  position: absolute;
  margin-left: calc(30px - (450px - 250px) / 2);
  overflow: hidden;
  right: 30%;
}

.globe-img {
  z-index: 1000;
  margin-left: -15%;
  border-radius: 50%;
}
