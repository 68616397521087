@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.container {
  display: flex;
  position: relative;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.self-center {
  align-self: center;
}

.justify-center {
  justify-content: center;
}

.m-0 {
  margin: 0px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.max-height {
  height: 100%;
}

.max-width {
  width: 100%;
}

.relative {
  position: relative;
}

.sidebar-block {
  width: 44px;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
}

.content-block {
  width: calc(100% - 44px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.white-font {
  color: white;
  font-family: 'Abel';
}

.grey-font {
  color: #999999;
  font-family: 'Abel';
}

.gold-font {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #b68b2f 30%, #b99541 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #927531 62.5%, #8a7033 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Abel';
}

.center-text {
  text-align: center;
}

@keyframes animate-toggle-in {
  from { 
    left: -100%;
  }
  to {
    left: 0%;
  }
}

@keyframes animate-toggle-out {
  from { 
    left: 0%;
  }
  to {
    left: -100%;
  }
}

.img-bg-big {
  background-image: url("./images/Globes/indo.png");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.close-menubar-toggle {
  min-width: 20px;
  width: 20%;
  fill: #e6e5e5;
  cursor: pointer;
}

.close-menubar-toggle:hover {
  fill: url(#gold-gradient) goldenrod;
}
