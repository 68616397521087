.marginEverySOM{
    margin-left:-10px;
}

.marginEverySOMBig{
    margin-left:30px;
}

.titleSOM{
    text-align:center;
    margin-bottom:-1em;
    margin-top: 0.5em;
    font-size:16px;
    color:white;
}

.tooltip-hexabin{
    font-size:12px;
    position:absolute;
    text-align:center;
    padding:8px;
    background-color:rgba(218, 165, 32, 0.8);
    border:0px;
    border-radius: 8px;
    pointer-events:none;
    color:black;
}

.closeChart{
    position:absolute;
    right: 0.5em;
    top:0;
}