#regional-geological-stratigraphy-graphic {
  display: flex;
  width: 400px;
  height: calc(100% - 40px);
  background-color: white;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 5px;
  margin: 20px 0px 20px 20px;
}

.regional-geological-stratigraphy-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2px 10px;
  font-family: Abel;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.7);
  border: 2px solid black;
  border-radius: 8px;
  pointer-events: none;
  z-index: 1200;
}
