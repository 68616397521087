.tooltip-histogram{
    font-size:12px;
    position:absolute;
    text-align:center;
    padding:8px;
    background-color:rgba(218, 165, 32, 0.8);
    border:0px;
    border-radius: 8px;
    pointer-events:none;
    color:black;
}

#slider-range{
    font-size: 14px;
    color:white;
}

.slider-labels {
    margin-top: 10px;
}

.axis text {
    font-size: 10px;
}
  
.axis tspan {
    font-size: 8px;
}
  