.input-pass-form{
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0 4px 2px #797d79;
    background-color: white;
    padding: 7%;
    padding-top:2%;
}

.container-reset-pass{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    max-width: 50%;
}

.container-form-reset-pass{
    margin-top: 5%;
}

.logo-reset-pass{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.container-logo-pass{
    width:50%;
    margin-right: 9%;
}

.button-reset-pass{
    width: 100%;
    display: inline-block;
    border-radius: 4px;
    background-color: black;
    background-image: linear-gradient(to right, black, darkgoldenrod);
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 0.5rem;
    transition: all 0.5s;
    cursor: pointer;
    font-size: 1em;
    font-family: Abel;
    margin-top: 3%;
}

.button-reset-pass span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.7s;
}
  
.button-reset-pass span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: 1rem;
    transition: 0.7s;
}
  
.button-reset-pass:hover span {
    padding-right: 2rem;
}
  
.button-reset-pass:hover span:after {
    opacity: 1;
    right: 0;
}