.default-search-container {
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.default-search-input-container {
  min-height: 30px;
  height: 3%;
  min-width: 180px;
  width: 100%;
  position: relative;
}

.default-search-icon-search {
  position: absolute;
  width: 15px;
  padding: 3px 10px;
  cursor: pointer;
  fill: white;
  pointer-events: none;
}

.default-search-icon-remove {
  position: absolute;
  width: 12%;
  min-width: 30px;
  right: 0%;
  padding: 3px 10px;
  cursor: pointer;
}

.default-search-icon-remove:hover {
  fill: #ff1a1a;
}

.default-search-error {
  color: rgb(250, 93, 93);
  font-family: Abel;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 0.8rem;
}
