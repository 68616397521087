.detail-sitebar-menu-toggle-button {
  margin: 0px 3px;
  padding: 3px 5px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid black;
  transition: all 330ms;
}

.detail-sitebar-menu-toggle-button-active {
  margin: 0px 3px;
  padding: 3px 5px;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid black;
  transition: all 330ms;
}

.detail-sitebar-menu-toggle-button-text {
  margin: 0px;
  padding: 0px 10px;
  background-color: white;
  font-family: Abel;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 5px;
}

.detail-sitebar-menu-toggle-button-text-active {
  margin: 0px;
  padding: 0px 10px;
  font-family: Abel;
  font-size: 0.8rem;
  font-weight: 600;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #b68b2f 30%, #b99541 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #927531 62.5%, #8a7033 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.detail-sitebar-menu-toggle-button:hover {
  background-color: black;
}

.detail-sitebar-menu-toggle-button:hover .detail-sitebar-menu-toggle-button-text {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #b68b2f 30%, #b99541 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #927531 62.5%, #8a7033 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
