.searchbar {
  position: absolute;
  display: flex;
  z-index: 1003;
  width: 310px;
  overflow-y: hidden;
  max-height: 100%;
  animation-duration: 1s;
  left: 0%;
  background-color: rgb(17, 17, 17);
  height: 100%;
  justify-content: center;
}

.search-content-box {
  margin-left: 20px;
  margin-right: 0px;
  position: relative;
  overflow-y: auto;
  height: calc(100% - 130px);
}

.search-font {
  font: Abel;
  color: white;
  margin: 8px;
}

.search-font-header {
  font-weight: 600;
  font-size: 0.8rem;
}

.search-content-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.toggle-data-found-inactive {
  align-self: center;
  width: 15px;
  padding-top: 3px;
  stroke: #cfcfcf;
  cursor: pointer;
  stroke-width: 2px;
}

.toggle-data-found-inactive:hover {
  stroke: url(#gold-gradient) goldenrod;
}

.toggle-data-found-active {
  align-self: center;
  width: 15px;
  padding-top: 3px;
  cursor: pointer;
  stroke: url(#gold-gradient) goldenrod;
  stroke-width: 2px;
}

.toggle-data-found-active:hover {
  stroke: #cfcfcf;
}

.toggle-data-found-empty {
  align-self: center;
  width: 15px;
  padding-top: 3px;
  stroke: #cfcfcf;
  stroke-width: 2px;
}
