.input-email-form{
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0 4px 2px #797d79;
    background-color: white;
    padding-left: 3rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
}

.container-reset{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    max-width: 50%;
}

.text-center-reset {
    text-align: center;
    font-size: 1.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: abel;
}

.text-center-input {
    text-align: center;
    font-family: abel;
}

.pt{
    padding-top: 1.5rem;
}

.logo-reset{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.button-reset{
    width: 35%;
    display: inline-block;
    border-radius: 4px;
    background-color: black;
    background-image: linear-gradient(to right, black, darkgoldenrod);
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 0.5rem;
    transition: all 0.5s;
    cursor: pointer;
    font-size: 1em;
    font-family: Abel;
}

.button-reset span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.7s;
}
  
.button-reset span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: 1rem;
    transition: 0.7s;
}
  
.button-reset:hover span {
    padding-right: 2rem;
}
  
.button-reset:hover span:after {
    opacity: 1;
    right: 0;
}

.text-reset{
    text-align: center;
    font-family: abel;
}