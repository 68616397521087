.technical-parameters-score-container {
  width: 30%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.technical-parameters-graphics-container {
  width: 75%;
  position: relative;
}

.technical-parameters-score {
  width: 80px;
  height: 80px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin: 10px 0px;
}

.technical-parameters-score-circle {
  position: absolute;
  width: 86px;
  height: 86px;
  left: auto;
  right: auto;
  border-radius: 50%;
}

.technical-parameters-detail {
  display: flex;
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
  background-color: rgb(240, 240, 240);
  overflow-x: auto;
  overflow-y: hidden;
  border: 2px solid rgb(187, 187, 187);
}

.technical-parameters-detail-content {
  min-width: 100px;
  height: 100px;
  background-color: red;
  margin: 10px;
}

.regional-geology-stratigraphy-container {
  min-height: 500px;
  position: relative;
}

.technical-parameters-structure-container {
  position: relative;
  margin: 20px;
  border: 1px solid rgb(158, 158, 158);
  background: white;
  border-radius: 5px;
  width: 300px;
  min-height: 500px;
}
