.register-square {
    background:  #fff;
    padding-right: var(--bs-gutter-x, 0.5rem);
    padding-left: var(--bs-gutter-x, 0.5rem);
    padding-top: var(--bs-gutter-x, 0.5rem);
    padding-bottom: var(--bs-gutter-x, 0.5rem);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    border: 0.25rem solid rgba(0, 0, 0, 0.055);
    border-radius: 1px ;
    box-shadow: 0 0 3px 1px rgb(255, 255, 255);
    width: 80%;
    align-self: flex-start;
    margin-top: 1%;
    margin-left: 17%;
    
}

.text-left-input {
    text-align: left;
    font-family: abel;
}

.text-right-input {
    text-align: right;
    font-family: abel;
}

.mt-2 {
    margin-top: 5em;
}

.p-4 {
    padding: 1.5rem;
}

.form-control {
    display: block;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-half {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
}

.col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.pt-3 {
    padding-top: 1rem;
}

.my-sm-3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.wd8{
    width: 80%;
    align-content: center;
}

.par{
    margin-top: -10;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}


.mb-2 {
    margin-bottom: 0.5rem;
}

.me-2 {
    margin-right: 1rem;
}

.abs{
    position: absolute;
}

.rel{
    position: relative;
}

.me-1 {
    margin-right: 2rem;
}

.me-3 {
    margin-right: 0.25rem;
}

.logo-reg{
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.align-self-center {
    align-self: center;
}

.ms-4 {
    margin-left: 1.5rem;
}

.line {
    color:darkgoldenrod;
    background-color: darkgoldenrod;
    height: 0.15rem;
    width: 100%;
    margin-bottom: 25px;
}

.par{
    margin-top: -2rem;
}

.grey {
    color: grey;
}

select:focus option.holder {
    display: none;
}

.button {
    display: inline-block;
    border-radius: 4px;
    background-color: black;
    background-image: linear-gradient(to right, black, darkgoldenrod);
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 1rem;
    width: 100%;
    transition: all 0.5s;
    cursor: pointer;
    font-size: 1.4em;
    font-family: Abel;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.7s;
}
  
.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -1rem;
    transition: 0.7s;
}
  
.button:hover span {
    padding-right: 2rem;
}
  
.button:hover span:after {
    opacity: 1;
    right: 0;
}

.register-container {
    display: flex;
}

.register-form-width {
    display: flex;
    width: 35%;
    max-width: 550px;
    min-width: 450px;
}

.logo-container-register {
    width: 46%;
    position: relative;
    margin: 2%;
}

.label-form-register {
    font-size: 0.9rem;
    margin-bottom: 3px;
    margin-top: 10px;
}

.logo-register {
    width: 100%;
    height: 38px;
}

.text-register {
    width: 100%;
    text-align: center;
    margin-top: 0px;
    font-weight: 600;
}

.register-box {
    border: 1px solid grey;
    border-radius: 5;
    box-shadow: 0 0 5px 2px #797d79;
    background: white;
    display: flex;
    flex-direction: row;
    margin: 50px auto;
    padding: 60px 80px;
}

.register-box-content {
    min-width: 450px;
    max-width: 550px;
    width: 75%;
    position: relative;
}

.register-form-content {
    justify-content: space-evenly;
    font-family: abel;
}

.register-creator-content {
    margin-top: 1%;
    margin-left: 1%;
}

.register-header {
    margin-top: 0px;
    font-size: 230%;
}

.img-bg{
    background-image: url("./bg-register.png");
    background-position:center ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.text-error{
    color: red;
    font-size: small;
    margin: 0%;
}

.btn-disable{
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;
}