.rightbar-toggle-button {
  position: absolute;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.247);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 4.5rem;
  width: 0.7rem;
  box-shadow:  -1px 1px 9px 1px #888888;;
  margin: 3px 0px;
  top: calc(50% - 3.5%);
  left: -15px;
  z-index: 1300;
  transition: background-color 330ms;
}

.rightbar-toggle-button:hover {
  background-color: black;
}

.rightbar-toggle-button:hover .rightbar-toggle-icon {
  fill: white;
  width: 0.7em;
  transition: fill 330ms;
}