.similar-project-header-container {
  position: relative;
  display: flex;
  height: 28px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgb(0, 0, 90);
}

.similar-project-header-header {
  margin: 0px;
  font-family: Abel;
  font-weight: 700;
  font-size: 1rem;
}

.similar-project-header-find {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  transition: all 330ms;
}

.similar-project-header-compare {
  position: absolute;
  display: flex;
  align-items: center;
  transition: all 330ms;
  margin-top: 2px;
}

.similar-project-find {
  position: relative;
  height: 21px;
  font-family: Abel;
  font-weight: 600;
  font-size: 0.6rem;
  margin-top: 5px;
  cursor: pointer;
  background-color: rgb(255, 214, 139);
  border: 1px solid grey;
  border-radius: 2.5px;
}

.similar-project-find-text {
  margin-top: 6.5%;
}

.similar-project-find:focus {
  outline: 0;
}

.similar-project-find:hover {
  background-color: rgb(255, 189, 65);
}

.similar-project-container {
  width: 99.5%;
  height: 94%;
  position: relative;
}

.similar-project-content {
  width: 100%;
  height: 100%;
  border: 0.1px solid rgb(167, 167, 167);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 330ms;
  position: absolute;
}

.similar-project-compare {
  width: 100%;
  height: calc(100% - 20px);
  transition: all 330ms;
  position: absolute;
  font-family: Abel;
  font-weight: 600;
  font-size: 0.8rem;
}

.match-sites-container {
  display: flex;
  position: relative;
  height: 29%;
  padding: 15px;
  width: 100%;
}

.match-sites-left-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.match-sites-right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  font-family: Abel;
  font-size: 0.8rem;
  font-weight: 600;
}

.match-sites-content-image {
  width: 150px;
  border-radius: 5px;
}

.match-sites-icon {
  fill: white;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-left: -2.5px;
}

.match-sites-content-button {
  display: flex;
  width: 45%;
  justify-content: center;
  margin: 5px;
  font-family: Abel;
  font-weight: 600;
  font-size: 0.6rem;
  border-radius: 2.5px;
  border: 1px solid transparent;
  box-shadow: -1px 1px 5px 0px grey;
  color: white;
  padding: 3px 0px;
  cursor: pointer;
  transition: all 330ms;
  z-index: 1400;
}

.match-sites-content-button:focus {
  outline: 0;
}

.match-sites-visit {
  background-color: rgb(93, 182, 255);
}

.match-sites-visit:hover {
  background-color: rgb(23, 150, 253);
}

.match-sites-compare {
  background-color: rgb(255, 183, 116);
}

.match-sites-compare:hover {
  background-color: rgb(255, 151, 53);
}

.match-sites-text {
  margin: 3px;
}

.match-sites-text-value {
  position: absolute;
  margin: 0px;
  padding-top: 2px;
  padding-left: 135px;
}

.compare-return-icon {
  fill: rgb(179, 179, 179);
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.similar-project-header-compare:hover .compare-return-icon {
  fill: rgb(136, 237, 255);
}

.similar-project-compare-flex {
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 2px solid rgb(219, 219, 219);
}

.similar-project-compare-header-left-flex {
  display: flex;
  width: 25%;
  align-items: center;
}

.similar-project-compare-header-flex {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
  position: relative;
}

.similar-project-compare-image {
  width: 60px;
  border-radius: 50%;
}

.similar-project-compare-text {
  margin: 12px;
}
