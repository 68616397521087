#structure-graphic {
  position: relative;
  width: 100%;
  height: 100%;
}

.technical-parameters-structure-tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2px 10px;
  font-family: Abel;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.7);
  border: 2px solid black;
  border-radius: 8px;
  pointer-events: none;
  z-index: 1200;
}
