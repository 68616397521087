.site-references-container {
  width: 99.5%;
  height: calc(100% - 100px);
  position: relative;
}

.site-references-content {
  margin-top: 5px;
  width: 100%;
  height: 100%;
  border: 0.1px solid rgb(167, 167, 167);
  transition: all 330ms;
  position: absolute;
}

.icon-site-references-menu{
  fill: rgb(179, 179, 179);
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}

.table-site-references-menu tr:nth-child(even){background-color: white}
.table-site-references-menu tr:nth-child(odd){background-color: rgb(236,236,236)}
.table-site-references-menu thead tr{background-color: white !important}
.table-site-references-menu{
    margin-left: auto;
    margin-right: auto;
    font-size:0.7em;
    font-family: abel;
    border-collapse: collapse;
    overflow: auto;
    width:700px;
}
.table-site-references-menu thead{
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.table-site-references-menu td{
    text-align: center;
    padding: 0.1em;
}
.table-site-references-menu tr{
    border-radius: 5px;
}

.icon-header-site-references-menu{
  height:15px;
  width:20px;
}
