.button-back-login{
    display: inline-block;
    border-radius: 4px;
    background-color: black;
    background-image: linear-gradient(to right, black, darkgoldenrod);
    border: none;
    color: #FFFFFF;
    text-align: center;
    padding: 0.7rem;
    width: 200%;
    transition: all 0.5s;
    cursor: pointer;
    font-size: 1em;
    font-family: Abel;
}

.button-back-login span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.7s;
}
  
.button-back-login span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: 2rem;
    transition: 0.7s;
}
  
.button-back-login:hover span {
    padding-right: 1rem;
    
}
  
.button-back-login:hover span:after {
    opacity: 1;
    right: 0;
}

.link-login{
    width: 50%;
}