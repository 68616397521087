.similar-project-dropdown-container {
  margin-top: 5px;
  margin-right: 10px;
  position: relative;
  width: 20%;
  font-family: Abel;
  font-weight: 500;
  height: 19px;
}

.similar-project-dropdown {
  position: absolute;
  border-radius: 2.5px;
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
  border: 1px solid grey;
  z-index: 1400;
}

.similar-project-dropdown-text {
  position: absolute;
  margin-top: 3%;
  left: 5%;
  transition: 330ms;
  font-weight: 600;
  font-size: 0.7rem;
}

.similar-project-dropdown-select-collapse {
  position: absolute;
  right: 8%;
  width: 10px;
  top: -9%;
}

.similar-project-dropdown-option {
  position: absolute;
  left: -0.5%;
  padding-left: 5%;
  cursor: pointer;
  width: 95%;
  height: 19px;
  font-size: 13px;
  transition: all 330ms;
  border: 1px solid grey;
}

.similar-project-dropdown-option-text {
  transition: all 330ms;
  font-weight: 600;
  font-size: 0.7rem;
}

.option-strip-grey {
  background-color: white;
}

.option-strip-grey:hover {
  background-color: rgb(223, 223, 223);
}

.option-strip-white {
  background-color: rgb(255, 231, 172);
}

.option-strip-white:hover {
  background-color: rgb(253, 217, 124);
}

.similar-project-dropdown-span {
  position: absolute;
  transition: all 330ms;
  z-index: 10;
}
