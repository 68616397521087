.detail-sitebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3003;
  width: 510px;
  overflow-y: hidden;
  max-height: 100%;
  right: 0;
  height: calc(100% - 44px);
  transition: all 500ms;
  scroll-behavior: unset;
}

.detail-sitebar-layer-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}

.detail-sitebar-header {
  margin: 10px;
  padding: 10px;
  border-bottom: 3px solid white;
  width: 92%;
  height: 7%;
  display: flex;
  z-index: 1004;
}

.detail-sitebar-header-image {
  background-color: white;
  padding: 3px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 1003;
}

.detail-sitebar-icon {
  fill: #d1d1d1;
  stroke: #d1d1d1;
  position: absolute;
}

.home-sitebar-icon {
  right: 150px;
}

.home-sitebar-icon-pick:hover {
  fill: rgb(119, 201, 255);
  stroke: rgb(119, 201, 255);
  cursor: pointer;
}

.back-sitebar-icon {
  right: 110px;
  stroke-width: 2;
}

.back-sitebar-icon-pick:hover {
  fill: rgb(119, 201, 255);
  stroke: rgb(119, 201, 255);
  cursor: pointer;
}

.forward-sitebar-icon {
  fill: #d1d1d1;
  right: 70px;
  stroke-width: 2;
}

.forward-sitebar-icon-pick:hover {
  fill: rgb(119, 201, 255);
  stroke: rgb(119, 201, 255);
  cursor: pointer;
}

.close-sitebar-icon {
  fill: #ff6666;
  stroke: #ff6666;
  right: 30px;
  cursor: pointer;
}

.close-sitebar-icon:hover {
  fill: #ff1a1a;
}

.detail-sitebar-header-text {
  color: white;
  font-family: Abel;
  font-size: 0.8rem;
  margin: 1.5px 20px;
}

.detail-sitebar-header-text-name {
  font-weight: 900;
  font-size: 1.4rem;
}

.detail-sitebar-menu-container {
  margin-left: 5px;
  z-index: 1003;
  /* overflow: scroll hidden; */
  width: 100%;
}

.detail-sitebar-menu-toggle {
  display: flex;
  margin-left: 5px;
  /* width: 40em; */
}

.sitebar-menu {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  padding: 10px;
  overflow-y: auto;
  height: 80%;
  z-index: 1003;
  border-radius: 3px;
}

.sitebar-menu-content {
  margin: 0px 5px;
}

.sitebar-menu-content-header {
  margin: 0px;
  font-family: Abel;
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 12px;
  border-bottom: 2px solid rgb(0, 0, 90);
  position: relative;
}

.sitebar-menu-content-sub-header {
  margin: 0px;
  font-family: Abel;
  font-weight: 700;
  font-size: 0.85rem;
}

.sitebar-menu-border-header {
  height: 5px;
  position: absolute;
  background-color: rgb(0, 0, 90);
  left: 0;
  bottom: 0;
}

.sitebar-menu-content-container {
  margin: 10px 0px;
  font-family: Abel;
  font-weight: 600;
  font-size: 0.8rem;
  position: relative;
  text-align: justify;
}

.sitebar-menu-content-image {
  width: 100%;
  min-height: 300px;
  transition: opacity 330ms;
}

.sitebar-menu-content-text {
  margin: 0px;
}
