.custom-rte-toolbar {
  transform: scale(0.65);
  width: 32em;
  margin-left: -5.8em;
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.2em;
  margin-bottom: -0.6em;
  border-radius: 5px;
  height: 5em;
  overflow-y: auto;
}

.rdw-dropdown-optionwrapper {
  height: 5em;
}

.public-DraftStyleDefault-ltr {
  margin: 0;
}

button.custom-rte-editor {
  width: 0.5em;
}

.custom-rte-editor {
  font-family: Abel;
  position: relative;
  font-size: 0.9em;
  height: 15vh;
  border: none;
  outline: none;
  background-color: white;
  padding: 0.2em;
  margin-top: 0.2em;
  border-radius: 5px;
}
