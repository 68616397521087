.icon-analysis{
    width: 1em;
}
.icon-analysis-up{
    width: 3em;
}
.text-header-analysis{
    text-align: left;
    font-size: 11px;
    white-space: nowrap;
    cursor:move;
    margin: 0;
    margin-left:0.5em;
    margin-right:0.5em;
    border-top: 1px solid rgba(119, 89, 13, 0.705);
    font-weight: bold;
}

.text-content-header-analysis-number{
    text-align: right;
    font-size: 22px;
    margin: 0;
    color: #c9c9c9;
    margin-right: 0.5em;
    margin-top: 0.2em;
}

.text-content-header-analysis{
    text-align: center;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    color: #c9c9c9;
    margin-top:0.3em;
    margin-bottom:0.7em;
}

.header-information-container{
    display:flex;
    position:relative;
    height:100%;
    margin:0.2em;
    flex-direction:column;
}


