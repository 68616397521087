.technical-parameters-graphics-container {
  position: relative;
}

.technical-parameters-tooltip {
  position: absolute;
  flex-direction: column;
  text-align: center;
  padding: 2px 10px;
  font-family: Abel;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.7);
  border: 2px solid black;
  border-radius: 8px;
  pointer-events: none;
  z-index: 1600;
}