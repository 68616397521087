.selectedScatter{
    opacity: 1;
    stroke: black;
    stroke-width: 1px;
}

.axisTitle{
    font-size: 0.8em;
}

.titleScatterPlot{
    font-size:larger;
    text-align:center;
    display:block;
    position:relative;
    margin-left:170px;
    font-weight: bold;
    margin-top: 10px;
}

.addButton{
    display: inline-block;
    text-decoration: none;
    width: 40px;
    height: 40px;
    font-size: 30px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-weight: bold; 
    background: #2196F3;
    cursor: pointer;
    padding: 0;
    border: 2px solid #2196F3;
    opacity: 1;
}

.addButton:hover {
    color: hsl(30, 70%, 50%);
    background-color: rgba(255, 255, 255);
    border: 2px solid darkgoldenrod;
    
}
.addButton:active {
    background-color: rgb(114, 114, 114);
    color: hsl(30, 100%, 50%);
    border: 2px solid rgb(255, 145, 0);
}

.textAddButton{
    text-align:center;
    font-weight: bold;
    margin-top:5px;
}

.tooltip-scatter{
    font-size:12px;
    position:absolute;
    text-align:center;
    padding:8px;
    background-color:rgba(218, 165, 32, 0.8);
    border:0px;
    border-radius: 8px;
    pointer-events:none;
    color:black;
  }
  .text-header-plot-scatter{
    margin-top: 15px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 3px;
    white-space: nowrap;
    margin-bottom: 2px;
    opacity: 0;
}

.center-add-button-scatter{
    opacity: 1;
    text-align: center;
    position:relative;
    margin-top: 40px;
}

#selectYScatter, #selectXScatter{
    margin-left: 0em;
    margin-top: 0em;
    border: none;
    font-size: 14px;
    font-family:Abel;
    font-weight:bolder;
    color:white;
    background: transparent;
    -webkit-appearance: none;
    text-align: center;
    width:40px;
}

#selectYScatter option, #selectXScatter option{
    font-size: 12px;
    font-weight: normal;
    font-family:Abel;

}

#selectXScatter:hover, #selectYScatter:hover{
    cursor: pointer;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .1em top 50%, 0 0;
    background-size: .65em auto, 100%;
    text-align: left;
    color:black;
    font-size: 12px;
    margin-top: 0.5em;
}
.checkboxScatterX{
    margin-left:3.9em;
}
.checkboxScatterY{
    margin-left:0.7em;

}
.logScaleTextScatter{
    margin-top:0.1em;
    font-size:10px;
    color:white;
}

.closeScatter{
    cursor: pointer;
    top: 0;
    opacity:0.5;
    right: 0;
    position:absolute;
    margin-right: 0.5em;
}

.closeScatter:hover{
    opacity:1;
}

.container-transparant-scatter{
    height: 309px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    background-color:#2e2e2e;
    opacity:0.5;
    align-items: center;
    border: 1px solid black;
    margin-top: 1.3px;
    position: -webkit-sticky;
    /* position: fixed; */
    right: 0;
    margin-right: 25px;
}

.container-transparant-scatter:hover{
    background-color:#808080;
    opacity:0.7;
    color:black;
}

.button-add{
    opacity:1;
    margin-top:130px;
    margin-left:10px;
    margin-right:10px;
}

.container-scatter-group{
    width: 95%;
}

.space-transparant-button{
    width:80px;
    position:relative;
    display: block;

}

.unhighlighted {
    stroke: rgba(99, 99, 99, 0.1) !important;
    fill: rgba(206, 206, 206, 0.1) !important;
}

.highlightMap {
    stroke: gold !important;
    fill: goldenrod !important;
}

.highlightScatter {
    stroke: deepskyblue !important;
    fill: dodgerblue !important;
}

.selection, .terminator {
    stroke: rgb(255, 255, 255);
    stroke-width: 1;
    fill: rgba(128, 128, 128, 0.199);
}

/* path {
    stroke: rgb(255, 255, 255);
    stroke-width: 1;
    fill: none;
  } */

.regressionLine{
    stroke: rgba(218, 165, 32);
    opacity: 0.3;
    fill: none;
    stroke-width: 3;
    /* stroke-dasharray: 3,5; */
}

.regressionLine:hover{
    opacity:1;
}

.textRegressionLine{
    fill:darkgoldenrod;
    font-size: small;
    font-family: abel;
    stroke:rgba(218, 165, 32, 0.3);
    border:#2e2e2e

}

.layoutScatter{
    width:1450px;
    position:relative;
}

.tooltip-scatter{
    font-size:12px;
    position:absolute;
    text-align:center;
    padding:8px;
    background-color:rgba(218, 165, 32, 0.8);
    border:0px;
    border-radius: 8px;
    pointer-events:none;
    color:black;
}

.img-menu-scatter{
    width:1.1em;
}

.container-menu-scatter {
    background-color:rgb(160, 160, 160);
    border-radius:4px;
    float:center;
    cursor: pointer;
    top: 4.5em;
    opacity:0.5;
    right: 0.5em;
    position:absolute;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}
.container-menu-scatter input + span{
    border-radius:4px;
    cursor: pointer;
    padding:0.1em;
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.container-menu-scatter input:hover + span {
    background-color:rgb(248, 248, 248);

}

.container-menu-scatter input:checked + span {
    background-color:rgb(255, 192, 34);
}

.container-menu-scatter input:checked:hover + span {
    background-color:goldenrod;
}

.select-tool-menu-scatter{
    cursor:crosshair;
}
